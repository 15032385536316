import React from 'react';
import { PAGE_CATEGORY, PAGE_TYPES } from 'components/Globals/Analytics/constants';
import ErrorPage from '../containers/ErrorPage';

const Custom404 = () => <ErrorPage statusCode={404} />;

Custom404.pageCategory = PAGE_CATEGORY.LANDING;
Custom404.pageType = PAGE_TYPES.ERROR_404;

export default Custom404;
